$(document).ready(function() {
  var offset = 250; // Offset to show Back to Top-button

  $(window).scroll(function() {
    if ($(this).scrollTop() > offset) {
      $('.top').fadeIn(500);
    } else {
      $('.top').fadeOut(500);
    }
  });

  // Show Footer
  $('body').on('click', '.top, footer', function(event) {
    var footerHeight = $('.footer').outerHeight();
    var windowHeight = $(window).height();
    var mouseY = event.pageY;
    var windowY = $(window).scrollTop();

    var clickAboveFooter = mouseY - windowY < windowHeight - footerHeight;

    if ( this.nodeName == 'FOOTER' && !clickAboveFooter ) return;
    
    event.preventDefault();
    $('html').toggleClass('showmenu');
  });
});
